import React, { useState } from "react"
import { graphql } from "gatsby"
import NewLayout from "../components/new_layout"
import Seo from "../components/seo"
import CardEvent from "../components/v2023/UI/Card/card-event"
import Certifications from "../components/v2023/Certifications/certifications"
import Identity from "../components/v2023/Identity/identity"
import { Carousel } from "react-responsive-carousel"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import moment from "moment"

const Events = ({ data, pageContext }) => {
    const lang = pageContext.langKey
    const page = data.page
    const prev_events = data.events.edges.filter(e => moment(e.node.date_start).isBefore(new Date()))
    const next_events = data.events.edges.filter(e => moment(e.node.date_start).isAfter(new Date())).sort((a, b) => new Date(a.node.date_start) - new Date(b.node.date_start))
    const events = next_events.concat(prev_events)
    const eventsByTecalis = data.eventsByTecalis.edges
    const principal = events[0].node
    const highlights = events.slice(1, 4)

    const [carouselIndicator, setCarouselIndicator] = useState(0)
    const [eventSize, setEventSize] = useState(9)
    const [eventListed, setEventListed] = useState(events.slice(4, 4 + eventSize))

    const showMoreEvents = async () => {
        let s = eventSize

        if (s < events.length) {
            s += 9
            setEventSize(s)
            setEventListed(events.slice(4, 4 + s))
        }
    }

    const nextCarousel = () => {
        if (carouselIndicator === (eventsByTecalis.length - 1)) {
            setCarouselIndicator(0)
            return
        }

        setCarouselIndicator(carouselIndicator + 1)
    }

    const prevCarousel = () => {
        if (carouselIndicator === 0) {
            setCarouselIndicator(eventsByTecalis.length - 1)
            return
        }

        setCarouselIndicator(carouselIndicator - 1)
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={lang}
                title={page.seo.title}
                description={page.seo.meta_description}
                image={page.seo?.image_2?.localFile?.publicURL}
                translates={pageContext.translates}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg white-section green-sm">
                    <div className="container container--title">
                        <h1 lang={lang} className="merriweather">{page.title}</h1>
                    </div>
                    <div className="container flex-start">
                        <div className="container__50" data-aos="fade-right">
                            <CardEvent event={principal} principal={true} lang={lang} customClass={'card--big'} />
                        </div>
                        <div className="container__50"  data-aos="fade-left">
                            {highlights.map((event, index) => (
                                <CardEvent
                                    key={index}
                                    event={event.node}
                                    lang={lang}
                                    horizontal={true}
                                />
                            ))}
                        </div>
                    </div>
                </div>

                {eventsByTecalis.length > 0 && <div className="main__section main__section--100 color-section purple">
                    <div className="container">
                        <ReactMarkdown children={page.by_tecalis_underline} rehypePlugins={[rehypeRaw]} />
                        <div className="carrousel carrousel--touch">
                            <div className="carrousel__content">
                                <div className="carrousel__content__button carrousel__content__button--prev">
                                    <button className="button-icon button-icon--secondary button-icon--lg" onClick={prevCarousel}>
                                        <i className="icon-arrow-left"></i>
                                    </button>
                                </div>

                                <div className="carrousel__content__box">
                                    <Carousel
                                        showArrows={false}
                                        showStatus={false}
                                        showThumbs={false}
                                        showIndicators={false}
                                        selectedItem={carouselIndicator}
                                        onChange={(index) => setCarouselIndicator(index)}
                                    >
                                        {eventsByTecalis.map((event, index) => (
                                            <CardEvent
                                                key={index}
                                                lang={lang}
                                                event={event.node}
                                                showDescription={true}
                                                horizontal={true}
                                            />
                                        ))}
                                    </Carousel>
                                </div>

                                <div className="carrousel__content__button  carrousel__content__button--next">
                                    <button className="button-icon button-icon--secondary button-icon--lg" onClick={nextCarousel}>
                                        <i className="icon-arrow-right"></i>
                                    </button>
                                </div>
                            </div>

                            <div className="carrousel__dots">
                                {eventsByTecalis.map((item, index) => {
                                    return <a className={`dots__dot ${carouselIndicator === index ? 'dots__dot--active' : ''}`} key={index} onClick={() => setCarouselIndicator(index)} />
                                })}
                            </div>
                        </div>
                    </div>
                </div>}

                <div className="main__section main__section--100">
                    <div className="container">
                        <h2>{page.all_events}</h2>
                        <div className="grid-lg-3">
                            {eventListed.map((event, index) => (
                                <CardEvent
                                    key={index}
                                    event={event.node}
                                    showDescription={true}
                                    lang={lang}
                                />
                            ))}
                        </div>

                        {eventListed.length < (events.length - 4) && <div className="container__see-more">
                            <button className="button button--xl button--secondary" onClick={showMoreEvents}>{page.view_more}</button>
                        </div>}
                    </div>
                </div>

                <Certifications />

                <Identity lang={lang} />
            </main>
        </NewLayout>
    )
}

export default Events

export const eventsQuery = graphql`
    query ($langKey: String) {
        page: strapi2023Event(locale: {eq: $langKey}) {
            seo {
                title
                meta_description
                image_2 {
                    localFile {
                        publicURL
                    }
                }
            }
            title
            by_tecalis
            by_tecalis_underline
            all_events
            view_more
        }
        events: allStrapiEvents(
            filter: {locale: {eq: $langKey}, by_tecalis: {eq: false}}
            sort: { fields: [date_start], order: [DESC] }
        ) {
            edges {
                node {
                    seo {
                        meta_description
                    }
                    title
                    description
                    date_start
                    date_end
                    image_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 1125
                                    quality: 100
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    location {
                        city
                    }
                    url
                }
            }
        }

        eventsByTecalis: allStrapiEvents(
            filter: {locale: {eq: $langKey}, by_tecalis: {eq: true}}
            sort: { fields: [date_start], order: [DESC] }
        ) {
            edges {
                node {
                    seo {
                        meta_description
                    }
                    title
                    description
                    date_start
                    date_end
                    image_preview {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 930
                                    quality: 100
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    location {
                        city
                    }
                    url
                }
            }
        }
    }
`
